.cd-table {
    width: 100%;
}

.timeSvg svg {
  width:16px;
  height: auto;
  margin-right: var(--sdds-spacing-layout-8);
  vertical-align: text-top;
}
.delete-spinner {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}