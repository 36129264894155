.confirmationDialogContainer { 
   
    @media only screen and (max-width: 950px) and (min-width: 400px) {
        .sdds-modal-md {
            width: 80%;
            
        }
    }

    @media only screen and (max-width: 1500px) and (min-width: 950px) {
        .sdds-modal-md {
            width: 40%;
            
        }
    }
    @media only screen and (min-width: 1500px) {
        .sdds-modal-md {
            width: 40% !important;
            max-width:100rem !important;
            
        }
    }

    .modalBodyOveride{
        min-height:30rem !important;
    }

    span {display: block;
        strong {
            padding-right: var(--sdds-spacing-layout-16);
        }
    }
    .spacing {
        padding: var(--sdds-spacing-layout-16) 0;
    }
    .sdds-modal-headline h5 {
        padding: 0; 
        font-weight: bold;
    }

}