.landing-page {
    display: flex;

    .overview-collapsed+.st-view-wrapper {
        width: calc(100vw - 13px);
    }

    .st-view-wrapper {
        width: calc(100% - 500px);
        .panels{
            padding: 0;
            width: 100%;
            display: block;
            overflow: hidden;
            .panel{
                padding: var(--sdds-spacing-layout-16);
                position: relative;
            }
            .active{
                height: calc(100vh - 130px);
                overflow-y: scroll;
            }
        }
        h4 {
            margin: 0;
            font-size: 16px
        }
        h5 {
            margin: 0;
            font-size: 14px;
            font-weight: normal;
            padding: var(--sdds-spacing-layout-8) 0;
            svg {
                width: 16px;
                vertical-align: middle;
                color: #2B70D3;
            }
        }
        .hydrated {
            display: flex;
            flex-direction: column-reverse;
        }
        .percentage {
            padding-top: 10px
        }
    }

    .st-view-container {
        display: flex;
        flex-direction: column;
        margin: 0 var(--sdds-spacing-layout-8);
    }

    .section-scrollable {
        overflow-y: scroll;
        height: calc(100vh - 220px);
    }

    .viewdata {
        .right {
            text-align: right;
        }

        svg {
            width: 20px;
            height: 20px;
            vertical-align: bottom;
            color: #2B70D3;
        }
    }

    .sdds-table .sdds-table__searchbar .sdds-table__searchbar-icon {
        z-index: 1;
    }

    .sdds-btn-icon svg{
        width:16px;
        height:16px;
      }

      .sdds-inline-tabs-fullbleed--tab{
        cursor: pointer;
        user-select: none;
        position: relative;
        z-index: 110;
        .tooltipLockIcon {
            position: relative;
            svg {
                height: 16px;
                vertical-align: bottom;
             }
            .hydrated {
             position: fixed;
             z-index: 999;
         }
    }
    }

}

.sdds-table .sdds-table__body-cell, .sdds-table .sdds-table__header .sdds-table__header-cell {
    min-width: inherit!important;
}
.sdds-table .sdds-table__header .sdds-table__header-cell {
    vertical-align: inherit;
}
.sdds-table .sdds-table__body-cell {
    vertical-align: baseline;
}